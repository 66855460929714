import { useNavigate } from "react-router-dom"

function Table({ cols, data }) {

    const navigate = useNavigate();

    function goToDetails(e, id) {
        e.preventDefault();
        console.log('id:'+id)
        console.log(e)
        navigate("contact-detail/"+id);
    }

    return(
        <table className="border-seperate">
            <thead>
                <tr>
                    {cols.map((col)=>{
                            return <th key={col}> {col}</th>
                        })}
                </tr>
            </thead>
            <tbody>            
                {data.map((row) => {
                    return <tr key={row.id} className="hover:cursor-pointer" onClick={(e)=>{goToDetails(e,row.id)}}>
                        <td className="border-2 border-primary">{row.id}</td>
                        <td className="border-2 border-primary">{row.name}</td>
                        <td className="border-2 border-primary">{row.email}</td>
                        <td className="border-2 border-primary">{row.phoneno}</td>
                        <td className="border-2 border-primary">{row.details}</td>
                    </tr>
                })}
            </tbody>
        </table>
    )
}

export default Table