import { useParams } from "react-router-dom"

const ContactDetails = () => {

    const { id } = useParams('id');

    return(
        <h1> Contact Details - {id}</h1>
    )
}

export default ContactDetails