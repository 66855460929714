import { Link } from 'react-router-dom';
import bgImage from './assets/bg2.jpg';
import pe from './assets/pe.jpg';

function Education () {
    return (
        <div className='flex flex-col rounded-xl bg-white items-center p-5 drop-shadow-2xl hover:bg-orange-100 hover:cursor-pointer'>
            <div className="flex  bg-orange-400 h-12 w-12 rounded-full items-center justify-center">
                <img src={pe} alt="Education" className="h-10 w-10 rounded-full"></img>
            </div>
            <div>
                <p className='text-2xl font-bold text-orange-500 py-5'>Primary Education</p>
            </div>
            <div className='text-justify'>
                <p>Education offers our children a foundation to achive their potential. However millions of children drop out of school. Pragathi Seva promotes primary education among underpriviliged chidlren through your partnership</p>
            </div>
        </div>
        
    )
}

function Skill () {
    return (
        <div className='flex flex-col rounded-xl bg-white items-center p-5 drop-shadow-2xl hover:bg-blue-100 hover:cursor-pointer'>
            <div className="flex  bg-blue-400 h-12 w-12 rounded-full items-center justify-center">
                <img src={pe} alt="Education" className="h-10 w-10 rounded-full"></img>
            </div>
            <div>
                <p className='text-2xl font-bold text-blue-500 py-5'>Skill Development</p>
            </div>
            <div className='text-justify'>
                <p>Pragathi Seva aims to provide skills, and competencies that enable unemployed youth to become self-employed</p>
            </div>
        </div>
    )
}

function Empowerment () {
    return (
        <div className='flex flex-col rounded-xl bg-white items-center p-5 drop-shadow-2xl hover:bg-green-100 hover:cursor-pointer'>
            <div className="flex  bg-green-400 h-12 w-12 rounded-full items-center justify-center">
                <img src={pe} alt="Education" className="h-10 w-10 rounded-full"></img>
            </div>
            <div>
                <p className='text-2xl font-bold text-green-500 py-5'>Women Empowerment</p>
            </div>
            <div className='text-justify'>
                <p>We work for the welfare and development of women from poor areas. We conduct awareness programs on Government initiatives and self employment schemes</p>
            </div>
        </div>
    )
}

function Header() {
    return(
        <div className='flex flex-col items-center'>
            <p className='text-white text-5xl mt-10 text-center'> Small Deeds, Big Difference</p>

            <p className='text-white text-2xl mt-4 text-center' >...for those who have the ability to take action, have the responsibility to take action</p>

            <p className=' text-white text-sm mt-10 w-2/3 text-center'>Pragathi is a secular, not-for-profit organization, registered under the Societies’ Registration Act of 2001. All donations are eligible for 50% tax exemption under section 80G of the Income Tax Act, 1961</p>

        </div>
    )
}



function Home () {
    return (
        <div className='h-screen bg-scroll w-full'>
            <div className='h-2/3 bg-cover' style={{backgroundImage: `url(${bgImage})`}}>
                <Header />
            </div>
            <div className='flex flex-col gap-5 lg:flex-row md:-mt-20 lg:-mt-40 items-center md:px-20'>
                <div className='lg:w-1/3 px-2'>
                    <Link to="work"><Education /></Link>
                </div>
                <div className='lg:w-1/3 px-2'>
                    <Link to="work"> <Skill /> </Link>
                </div>
                <div className='lg:w-1/3 px-2'>
                    <Link to="work"> <Empowerment /> </Link>
                </div>
            </div>

        </div>
    )

}

export default Home