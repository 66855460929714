import { Outlet } from "react-router-dom"
import Navbar from "./Navbar"
import { AuthProvider } from "./auth/AuthProvider"

const RootLayout = () => {
    return (
        <AuthProvider>
            <div>
                <Navbar></Navbar>
                <Outlet />
            </div>
        </AuthProvider>
    )
}

export default RootLayout