import { useEffect, useState } from "react"
import Table from "../../common/Table";
import axios  from "../../common/Axios";
import { useContext } from "react";
import AuthContext from "../../common/auth/AuthProvider";

const CONTACTS_URL = '/contacts/'
export default function Contacts() {

    const columns = ["id","name","email","phoneno","details"];
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { auth } = useContext(AuthContext);
    
    useEffect( () => {
        const controller = new AbortController();
        let isMounted = true;

        const getContacts = async() =>{
            console.log('contacts: getContacts: START');
            setIsLoading(true);
            try {
                console.log('contacts: getContacts: Getting Contacts');
                const response  = await axios.get(CONTACTS_URL+'?page_num=0&page_size=0',{
                signal:controller.signal,
            });
            if(isMounted) {
                console.log('response.data: '+ JSON.stringify(response.data));
                setContacts(response.data);
                setIsLoading(false);
            }
            } catch (error){
                if(error.name === 'CanceledError') {
                    console.log('ERROR: contacts: getContacts: Aborted');
                } else {
                    console.log('ERROR: contacts: getContacts: ')
                    throw error
                }
            }
        }
        getContacts();
        return () => {
            console.log('In return of UseEffect');
            isMounted = false;
            controller.abort();
        }

    },[]);

    return(
        <div className="flex flex-col items-center">
            <h1 className="text-xl text-primary"> Contacts</h1>
            {isLoading && <div> Loading </div>}
            <div className="flex justify-center m-5">
                {!isLoading && <Table cols={columns} data={contacts}/>}
            </div>
        </div>

    )
}

export function contactsLoader() {

}