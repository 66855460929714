import { NavLink, useNavigate } from "react-router-dom"
import pic from './assets/psslogo2.png';
import useAuth from "./auth/useAuth";

const Navbar = () => {
    const { auth,setAuth } = useAuth()
    const navigate = useNavigate();
    const logout = () => {
        console.log('logout:'+auth.username);
        setAuth({});
        navigate('/');
    }

    return(
        <div className="flex justify-between items-center">
            <div className="flex items-center p-2">
                <img src={pic} alt=""  width="50" height="50" />
                <h1 className="text-xl px-3">Pragathi Seva Society</h1>
            </div>
            <div className="hidden md:flex">
                <NavLink to="/" className="px-4 hover:underline"> Home </NavLink>
                <NavLink to="work" className="px-4 hover:underline"> Work </NavLink>
                <NavLink to="help" className="px-4 hover:underline"> How can you help? </NavLink>
                {auth.username?<NavLink to="contacts">Contacts</NavLink>:<></>}
                {auth.username
                    ? <button onClick={()=>{logout()}} className="px-4 hover:underline"> Logout </button>
                    : <NavLink to="login" className="px-4 hover:underline"> Login </NavLink>}
            </div>
            <div className="hidden lg:flex p-2 mx-2 rounded-lg bg-primary text-white border border-blue-400">
                +91-94926 62646
            </div>

        </div>

    )
}

export default Navbar