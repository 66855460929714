import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../Axios";
import useAuth from "./useAuth";

const LOGIN_URL = 'login/';

const Login = () => {

    const [login, setLogin] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg,setErrMsg] = useState("");
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const from = location.state?.from?.pathname||'/contacts';

    const updateData = (e) =>{
        console.log("Login: updateData: ");
        setLogin({...login, [e.target.name]:e.target.value})
        console.log("Login: updateData: "+JSON.stringify(login));
    }

    const loginSubmit = async (e) => {
        e.preventDefault()
        if(!login.username) {
            setErrMsg('Please provide the username/password');
            return
        }
        setIsLoading(true);
        setErrMsg("");
        try {
            const respone = await axios.post(
                LOGIN_URL, 
                JSON.stringify(login),
                {
                    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                    // withCredentials: true
                }
                )
            console.log('loginSubmit:'+JSON.stringify(respone.data))
            setIsLoading(false);
            if(respone.data.accessToken){
                console.log('loginSubmit: token: '+respone.data.accessToken+' username: '+login.username);
                setAuth({username:login.username,token:respone.data.accessToken});
            } else {
                setErrMsg('Invalid username/password')
            }
        } catch(err) {
            console.log('loginSubmit: In Error:'+JSON.stringify(err));
            if(!err?.respone) {
                console.log('loginSubmit: In Error:'+JSON.stringify(err));
                setErrMsg("No Server Respone. Please try later")
            } else {
                setErrMsg("Something Wrong. Please try later")
            }
            setIsLoading(false);
        }

    }

    useEffect(()=>{
        if(auth.username) {
            navigate(from)
        }
    },[auth,from])

    return(
        <div className="flex justify-center items-center">
            <div className="border shadow-xl m-10 p-5 bg-slate-200">
                <form onSubmit={loginSubmit}>
                    <div className="flex flex-col">
                        <label className=""> Username: </label>
                        <input name="username" type="text" onChange={updateData} className="border border-slate-300 rounded-md"></input>
                    </div>
                    <div className="flex flex-col mt-5">
                        <label> Password: </label>
                        <input name="password" type="password" onChange={updateData} className="border border-slate-300 rounded-md"></input>
                    </div>
                    {isLoading && <p className="text-red-400 my-4">Logining...</p>}
                    {errMsg && <p className="text-red-400 my-4">{errMsg}</p>}
                    <div className="flex justify-center m-5">
                        <button className="bg-primary px-4 m-5 rounded-lg"> Login </button>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default Login