import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import RootLayout from './common/RootLayout';
import Work from './features/work/Work';
import Home from './common/Home';
import About from './features/about/About'
import Help from './features/about/Help';
import Contacts from './features/contact/contacts';
import ContactLayout from './features/contact/ContactLayout';
import ContactDetails from './features/contact/ContactDetails'
import Login from './common/auth/Login';
import { AuthProvider } from './common/auth/AuthProvider';
import RequireAuth from './common/auth/RequireAuth';

import Test from './common/Test';

const router = createBrowserRouter(
  createRoutesFromElements (
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />}></Route>
      <Route path='work' element={<Work />}></Route>
      <Route path="help" element={<Help />}></Route>
      <Route path="about" element={<About />}></Route>
      <Route element={<RequireAuth />}>
        <Route path='contacts' element={<ContactLayout />} >
          <Route index element={<Contacts />} ></Route>
          <Route path='contact-detail/:id' element={<ContactDetails />}></Route>
        </Route>
      </Route>
      <Route path='login' element={<Login />}></Route>
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router}> </RouterProvider>
  );
}

export default App;
