import ContactCreate from "../contact/ContactCreate"

const Help = () => {
    return(
        <div className="flex flex-col md:flex-row">
            <div className="mx-12 my-6 md:w-1/2 md:mx-6 md:my-6 lg:mx-12 lg:my-12  text-justify p-5 ">
                <p className="text-2xl text-primary font-bold">How can you Help? </p>
                <p className="text-gray-700 py-2"> We depend solely on the donation for individual and corporates. Based on the funds available, we run the centres.</p>
                <li className="py-2"><span className="font-bold"> Donation:</span> We request you to support us with monetary donations to support our operations. All donations to Pragathi Seva Society are eligible for 50% tax exemption under section 80G of the Income Tax Act, 1961</li>
                <li className="py-2"><span className="font-bold">Equipment Donation:</span> Any unused digital equipment - laptops, tablet, or educational software licenses, will go a long way in providing quality content to our students.</li>
                <li className="py-2"><span className="font-bold">Employee Participation:</span> We are always on the lookout for volunteers who can spend time at the school or centres, and interact with the students. We welcome your employees to be part of this operation at a regular frequency. If they can spend their weekends, with the students, it will go a long way in encouraging the students to study.</li>
            </div>
            <div className="mx-12 my-6  md:w-1/2 md:mx-6 md:my-6 lg:mx-12 lg:my-12 text-justify p-5 bg-slate-200">
                <ContactCreate />
            </div>
        </div>
    )
}

export default Help