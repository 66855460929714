import bsdetail from './bsdetail.png';
import skill1img from './sd1.png';

function Education1() {
    return(
        <div className="flex items-center gap-10">
            <div className="w-1/2 text-justify">
                <p className="text-orange-400">Primary Education</p>
                <p className="text-primary text-3xl py-2">Badi Sandadi</p>
                <p>We believe our project – Project “Badi Sandadi”, will improve the learning of children in villages, and make education effective. Our project intends to complement the existing school infrastructure at three levels:</p>
                <li>Our volunteers will teach at the government school to complement the school teachers. Run “Badi Paluku” centres – a post school revision centres at the government schools or private centres. The centre will be run by volunteers. This will enable the children to reinforce their learning in the morning, by getting their doubts clarified, asking questions, or applying the concepts.</li>
                <li>Encourage physical fitness through sports, games and wellness events.</li>
                <li>Provie digital equipment to complement the volunteers.</li>

                An indirect benefit of this project would be to provide employment opportunities for the volunteers, who would otherwise have been unemployed.
            </div>
            <div className="w-1/2">
                <img src={bsdetail} alt="Badi Sandadi"></img>
            </div>
        </div>
    )
}

function Skill1() {
    return(
        <div className="flex items-center gap-10">

            <div className="w-1/2">
                <img src={skill1img} alt="Vocational Training" className='bg-cover'></img>
            </div>
            <div className="w-1/2 text-justify">
                <p className="text-blue-400">Skill Development</p>
                <p className="text-primary text-3xl py-2">Vocational Training</p>
                <p>We provide vocational training in diverse domains across the service and the manufacturing industry through our partners. Under this program, we provide courses like foundation skills and domain-specific areas that are aligned to the needs of the local indsutries.</p>
            </div>
        </div>
    )
}

function Aboutus() {
    return (
        <section className='flex items-center gap-10'>
            <div className='w-2/3 text-justify'>
                <p className="text-blue-400">About Us</p>
                <p className="text-primary text-3xl py-2">Pragathi Seva Society</p>
                <div>
                <p>Pragathi Seva Society was established with the purpose of enabling the economically weaker and socially backward sections of the society, to lead better lives. We focus on Primary Education, Women Empowerment, and Skill development. We started in 2017, and are based at Kesunapalli, a village in Guntur District and operate in the state of Andhra Pradesh.</p>
                <br></br>
                <p className='flex justify-center font-bold'>“Give a man a fish, and you feed him for a day. Teach a man to fish, and you feed him for a lifetime.”</p>
                <br></br>
                <p> The above though has been our approach in our work. We believe we should enable the disadvantaged, to improve their life by imparting skills or proficiency which can provide them opportunities to support a good life for themselves and their families. We work in three areas:</p> 
                <li> <span className='font-bold'>Education:</span> Education offers the youth a foundation to achieve their potential. However, millions of children drop out of schools. We promote primary education among underprivileged children through imparting lessons by our volunteers, where school staff is lacking, running free tuition points to reinforce their understanding, and conducting sports and games to engage the children.</li>
                <li> <span className='font-bold'>Women Empowerment:</span> We support the elderly, marginalised women, and girls to live a dignified live. Our programs enable the women, by providing capital, asset building, or opening up markets.</li>
                <li> <span className='font-bold'>Skill Development:</span> We run skill development programmes which enable the unemployed to develop competencies and become employable. We conduct vocational training programs – stitching and threading, computer courses, bangle making, candle making etc.</li>
                <br></br>
                <p>Our programmes are designed and implemented with a core focus on enablement. We enable people who are disadvantaged by providing opportunities and access to resources.</p>
                </div>
                <div></div>
            </div>
            <div className='md:w-1/3 bg-blue-400 text-white p-5 rounded-xl'>
                <li>Pragathi is a secular, not-for-profit organization in India, registered under the Societies’ Registration Act of 2001.</li>
                <br></br>
                <li>All donations are eligible for 50% tax exemption under section 80G of the Income Tax Act, 1961</li>
            </div>
        </section>
    )
}

function Work() {
    return (
        <div className="container mx-auto"> 
            <div className='m-10'>
                <Education1 />
            </div>
            <div className='m-10'>
                <Skill1 />
            </div>
            <div className='m-10'>
                <Aboutus />
            </div>
        </div>
    )
}

export default Work