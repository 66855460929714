import React, { useEffect, useState } from "react"
import axios from "../../common/Axios";

const USER_REGEX = /^[A-z][A-z0-9-_' ']{3,23}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const CONTACT_URL = 'contacts/'

const ContactCreate = () => {

    const [data, setData] = useState({"id":"0"});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [submitErrMsg,setSubmitErrMsg] = useState("");

    const isObjectEmpty = (objectName) => {
        return (
          objectName &&
          Object.keys(objectName).length === 0 &&
          objectName.constructor === Object
        );
    };

      
    const updateData = (e) => {
        setData({...data, [e.target.name]:e.target.value })
        console.log(data)
    }

    const clearForm = () =>{
        setData({id:"0",name:"",email:"",details:"",phoneno:""})
        setErrors({})
        setIsSubmit(false);
    }

    const submitForm = (e) => {
        e.preventDefault();
        setErrors(validate(data));
        setIsSubmit(true);
    }

    useEffect(()=>{
        const doPost = async () => {
            if(isObjectEmpty(errors) && isSubmit) {
                try {
                    console.log('CONTACT_URL:'+CONTACT_URL);
                    setIsLoading(true);
                    setIsSuccess(false);
                    await axios.post(
                        CONTACT_URL,
                        JSON.stringify(data),
                        {
                            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
                            // withCredentials: true
                        }
                    )
                    setIsSuccess(true);
                    setIsLoading(false);
                    clearForm();
                } catch(err){
                    setIsLoading(false);
                    if (!err?.response) {
                        setSubmitErrMsg('No Server Response. Please try later');
                    } else if (err.response?.status === 409) {
                        setSubmitErrMsg('Username Taken');
                    } else {
                        setSubmitErrMsg('Not able to save your data. Please try later')
                    }
                }
            }
        }
        doPost();
    },[errors]);

    const validate = (values) => {
        const errors = {};

        if(!values.name) {
            errors.name = "User Name is required";
        } else {
            if(!USER_REGEX.test(values.name)){
                errors.name = "Please enter a valid Name";
            }
        }
        if(!values.email && !values.phoneno) {
            errors.email = "Either email or phone no is required";
        }
        if(values.email) {
            if(!EMAIL_REGEX.test(values.email)){
                errors.email = 'Please enter a valid email id';
            }
        }
        if(values.phoneno) {
            if(!PHONE_REGEX.test(values.phoneno)){
                errors.phoneno = 'Please enter a valid phone no:'
            }
        }
        console.log("Retruning: "+JSON.stringify(errors));
        return errors;
    }

    return(
        <form onSubmit={submitForm}>
            <div className="text-2xl text-primary font-bold mb-5">
                {!isLoading && !isSuccess && !submitErrMsg && <p>We would love to hear from you</p>}
                {isLoading && <p className="text-green-600 font-bold">Saving the data</p>}
                {isSuccess && <p className="text-green-600 font-bold">Thank you for your help</p>}
                {submitErrMsg && <p className="text-green-600 font-bold">{submitErrMsg}</p>}
            </div>
            {/* <div className="flex justify-center">
                {isLoading && <p className="text-green-600 font-bold">Saving the data</p>}
                {isSuccess && <p className="text-green-600 font-bold">Thank you for your help</p>}
                {submitErrMsg && <p className="text-green-600 font-bold">{submitErrMsg}</p>}
            </div> */}
            <div className="flex flex-col">
                <label className="font-bold"> Name: </label>
                <input className="border border-slate-300 rounded-md py-2 outline-slate-200" type="text" name="name" value={data.name} onChange={updateData}>
                </input>
                {errors.name && <p className="text-red-400">{errors.name}</p>}
            </div>
            <div className="flex flex-col mt-2">
                <label className="font-bold"> Email: </label>
                <input className="border border-slate-300 rounded-md py-2 outline-slate-200" type="text" name="email" value={data.email} onChange={updateData}>
                </input>
                {errors.email && <p className="text-red-400">{errors.email}</p>}
            </div>
            <div className="flex flex-col mt-2">
                <label className="font-bold"> Phone No: </label>
                <input className="border border-slate-300 rounded-md py-2 outline-slate-200" type="text" name="phoneno" value={data.phoneno} onChange={updateData}>
                </input>
                {errors.phoneno && <p className="text-red-400">{errors.phoneno}</p>}
            </div>
            <div className="flex flex-col mt-2">
                <label className="font-bold"> Please let us know how can you help: </label>
                {/* <input className="border border-slate-300 rounded-md py-2" type="textarea" name="details" onChange={updateData}>
                </input> */}
                <textarea rows="3" cols="50" name="details" className="border border-slate-300 outline-slate-200" value={data.details} onChange={updateData} />
            </div>
            <div className="flex items-center justify-center">
                <button className="bg-primary rounded-xl p-2 m-5"> Submit </button>
            </div>

        </form>
    )
}

export default ContactCreate